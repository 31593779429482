<header class="zmovo-header header-desktop">
    <div class="container">
        <div class="zmovo-center align-items-center">
            <div class="zmovo-logos zmovo-menu-logo-wrapper">
                <div class="zmovo-logo">
                    <a (click)="goTOHome()"><img src="../../../assets/image/logo.png" alt=""></a>
                </div>

            </div>

            <div class="zmovo-t-right">
                <div class="zmovo-header-right">
                    <div class="zmovo-menus">
                        <div class="main-menu">
                            <div class="navigation">
                                <div class="menu-container">
                                    <div id="navigation" *ngIf="navigationMenu.length > 0">
                                        <ul>
                                            <li>
                                                <a [routerLink]="['home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-home" aria-hidden="true"></i>
                                                </span>Home</a>
                                            </li>
                                            <li *ngIf="showLiveVideoMenu == true">
                                                <a [routerLink]="['live-video', urlType]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-video-camera" aria-hidden="true"></i>

                                                </span>Live Video</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['radio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-microphone" aria-hidden="true"></i>
                                                </span>Live Radio</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['video-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-video-camera" aria-hidden="true"></i>

                                                </span>Videos</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['audio-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-music" aria-hidden="true"></i>

                                                </span>Music</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['favorite-audio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-heart" aria-hidden="true"></i>

                                                </span>Favorites</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['playlist']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-list-ul" aria-hidden="true"></i>

                                                </span>Playlists</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zmovo-top-search">
                        <div class="zmovo-ser-icon">
                            <span class="fa fa-search" (click)="onSubmitSearch()"></span>
                            <input type="text" class="form-control" required [(ngModel)]="searchtext" name="search" #search="ngModel" placeholder="Search Music Here..">
                        </div>
                    </div>
                    <div class="zmovo-login" *ngIf="isLogin == false">
                        <a (click)="login()" class="btn login-btn"><span class="fa fa-user"></span> Login</a>
                    </div>
                    <div id="navigation" *ngIf="isLogin == true">
                        <ul>
                            <li class="active has-sub"><span class="submenu-button"></span>
                                <a class="profile-menu">
                                    <img src="https://durisimoapp.com/durisimo/assets/images/avtar.png" *ngIf="(profileData?.image == '' || profileData?.image == null)" />
                                    <img [src]="profileData?.image" *ngIf="(profileData?.image != '' && profileData?.image != null)" />
                                </a>
                                <ul>
                                    <li><a (click)="goToProfile()">My profile</a></li>
                                    <li><a (click)="goToChangePassword()">Change Password</a></li>
                                    <li><a data-toggle="modal" data-target="#exampleModal">Logout</a></li>
                                </ul>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</header>

<!-- animated header -->
<header class="header-desktop animated-header">
    <div class="container">
        <div class="zmovo-center align-items-center">
            <div class="zmovo-logos zmovo-menu-logo-wrapper">
                <div class="zmovo-logo">
                    <a (click)="goTOHome()"><img src="../../../assets/image/logo.png" alt=""></a>
                </div>

            </div>

            <div class="zmovo-t-right">
                <div class="zmovo-header-right">
                    <div class="zmovo-menus">
                        <div class="main-menu">
                            <div class="navigation">
                                <div class="menu-container">
                                    <div id="navigation" *ngIf="navigationMenu.length > 0">
                                        <ul>
                                            <li>
                                                <a [routerLink]="['home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-home" aria-hidden="true"></i>
                                                </span>Home</a>
                                            </li>
                                            <li *ngIf="showLiveVideoMenu == true">
                                                <a [routerLink]="['live-video', urlType]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-video-camera" aria-hidden="true"></i>

                                                </span>Live Video</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['radio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-microphone" aria-hidden="true"></i>
                                                </span>Live Radio</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['video-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-video-camera" aria-hidden="true"></i>

                                                </span>Videos</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['audio-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-music" aria-hidden="true"></i>

                                                </span>Music</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['favorite-audio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-heart" aria-hidden="true"></i>

                                                </span>Favorites</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['playlist']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><span class="he-icon"><i class="fa fa-list-ul" aria-hidden="true"></i>

                                                </span>Playlists</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zmovo-top-search">
                        <div class="zmovo-ser-icon">
                            <span class="fa fa-search" (click)="onSubmitSearch()"></span>
                            <input type="text" class="form-control" required [(ngModel)]="searchtext" name="search" #search="ngModel" placeholder="Search Music Here..">
                        </div>
                    </div>
                    <div class="zmovo-login" *ngIf="isLogin == false">
                        <a (click)="login()" class="btn login-btn"><span class="fa fa-user"></span> Login</a>
                    </div>
                    <div id="navigation" *ngIf="isLogin == true">
                        <ul>
                            <li class="active has-sub"><span class="submenu-button"></span>
                                <a class="profile-menu">
                                    <img src="https://durisimoapp.com/durisimo/assets/images/avtar.png" *ngIf="(profileData?.image == '' || profileData?.image == null)" />
                                    <img [src]="profileData?.image" *ngIf="(profileData?.image != '' && profileData?.image != null)" />
                                </a>
                                <ul>
                                    <li><a (click)="goToProfile()">My profile</a></li>
                                    <li><a (click)="goToChangePassword()">Change Password</a></li>
                                    <li><a data-toggle="modal" data-target="#exampleModal">Logout</a></li>
                                </ul>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</header>

<div class="modal fade wrapper-model main-model logout" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-wrapper">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                <div class="modal-body">
                    <p>Are you sure you want to logout?</p>
                    <div class="btn-wrapper-model">
                        <button class="btn-lg model-btn" (click)="Logout()">Yes</button>
                        <button class="btn-lg model-btn" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- mobile  -->

<nav class="navbar navbar-fix mobile-menu">

    <div class="container">
        <div class="navbar-logo">
            <div class="logo-wrapper">
                <div class="zmovo-logo">
                    <a (click)="goTOHome()"><img src="../../../assets/image/logo.png" alt=""></a>
                </div>
                <div class="humbar-menu burgurmenu-menu">
                    <img src="../../../assets/image/white.svg">
                </div>
            </div>

            <div class="sidebar-menu burgurmenu-menu">
                <div class="close-menu">
                    <a>×</a>
                </div>
                <div class="wrapper-mobile">

                    <ul *ngIf="navigationMenu.length > 0">

                        <li *ngIf="isLogin == true">
                            <a class="profile-menu">
                                <img src="https://durisimoapp.com/durisimo/assets/images/avtar.png" *ngIf="(profileData?.image == '' || profileData?.image == null)" />
                                <img [src]="profileData?.image" *ngIf="(profileData?.image != '' && profileData?.image != null)" />
                            </a>
                        </li>
                        <li *ngIf="isLogin == false">
                            <a (click)="login()" class="btn login-btn"> Login</a>
                        </li>
                        <li>
                            <a [routerLink]="['home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Home</a>
                        </li>
                        <li *ngIf="showLiveVideoMenu == true">
                            <a [routerLink]="['live-video', urlType]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Live Video</a>
                        </li>
                        <li>
                            <a [routerLink]="['radio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Live Radio</a>
                        </li>
                        <li>
                            <a [routerLink]="['video-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Videos</a>
                        </li>
                        <li>
                            <a [routerLink]="['audio-category']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Music</a>
                        </li>
                        <li>
                            <a [routerLink]="['favorite-audio']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Favorites</a>
                        </li>
                        <li>
                            <a [routerLink]="['playlist']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Playlists</a>
                        </li>
                        <li *ngIf="isLogin == true"><a [routerLink]="['profile']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">My profile</a></li>
                        <li *ngIf="isLogin == true"><a [routerLink]="['password', 'change-password']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">Change Password</a></li>
                        <li *ngIf="isLogin == true"><a data-toggle="modal" data-target="#exampleModal" class="btn login-btn">Logout</a></li>
                    </ul>


                </div>


            </div>
        </div>
    </div>
    <div class="overlay burgurmenu-menu"></div>
</nav>
